export default function (email) {
    const tester = RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@([a-zA-Z0-9-]+[\\.][a-zA-Z0-9-]{2,})+$/);

    if (email.includes(" ")) {
        return false;
    }
    const parts = email.split("@");
    if (parts.length !== 2) {
        return false;
    }
    if (parts[0].length > 64) {
        return false;
    }
    else if (parts[1].length > 64) {
        return false;
    }
    if (!tester.test(email)) {
        return false;
    }
    return true;
}