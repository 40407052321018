export default function (pass) {
    if (!pass) {
        return false;
    }
    if (pass.length < 6 || pass.length > 50) {
        return false;
    }
    const regex = /[a-zA-Z]/;
    if (!regex.test(pass)) {
        return false;
    }
    if (pass.includes(" ")) {
        return false;
    }
    return true;
}