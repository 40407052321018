import axios from "axios";
import Env from "@/logics/Envs.js";

export default function (schoolsRef, schoolNamesRef) {
    axios.get(Env.freePath + "schools").then(function (res) {
        if (!res.data.error) {
            schoolsRef.value = res.data.schools;
            schoolNamesRef.value = res.data.schools.map(a => a.name);
        }
    });
}