<template>
    <van-config-provider :theme-vars="myTheme">
        <div class="flex flex-col items-center pt-32">
            <van-form ref="form" class="py-4 w-full md:w-2/3 lg:w-1/3" validate-first validate-trigger="onSubmit">
                <van-cell-group class="shadow-md" inset>
                    <van-field v-model="formData.email" maxlength="100" :label="lang[userLang].emailLabel"
                        :placeholder="lang[userLang].placeholderEnter"
                        :rules="[{ required: true, message: lang[userLang].emailMessage }, { validator: emailValidator, message: lang[userLang].emailFormatMessage }]" />

                    <van-field v-model="formData.password" type="password" maxlength="50"
                        :label="lang[userLang].passLabel" :placeholder="lang[userLang].placeholderEnter"
                        :rules="[{ required: true, message: lang[userLang].passMessage }, { validator: passValidator, message: lang[userLang].passFormatMessage }]" />

                    <van-field v-model="formData.userType.text" readonly clickable is-link
                        :label="lang[userLang].userTypeLabel" :placeholder="lang[userLang].placeholderPick"
                        @click="showUserType = true"
                        :rules="[{ required: true, message: lang[userLang].userTypeMessage }]" />

                    <van-field v-model="formData.school.name" readonly clickable is-link
                        :label="lang[userLang].schoolLabel" :placeholder="lang[userLang].placeholderPick"
                        @click="showSchools = true"
                        :rules="[{ required: true, message: lang[userLang].schoolMessage }]" />

                    <div class="text-center">
                        <van-image class="mt-2" v-if="formData.school.name" round fit="contain" width="8rem"
                            height="8rem" :src="Env.schoolPath + formData.school.icon" />
                    </div>
                </van-cell-group>
            </van-form>
            <van-button class="my-12 w-32" type="primary" :loading="submitting" @click="doLogin()">
                {{ lang[userLang].login }}
            </van-button>
            <div class="cursor-pointer text-blue-500 text-sm" @click="goReset()">{{ lang[userLang].forget }}</div>
        </div>
    </van-config-provider>
    <van-popup v-model:show="showUserType" position="bottom" round>
        <van-picker show-toolbar :columns="userTypes" @cancel="showUserType = false" @confirm="pickUserType"
            :confirm-button-text="lang[userLang].confirm" :cancel-button-text="lang[userLang].cancel" />
    </van-popup>
    <van-popup v-model:show="showSchools" position="bottom" round>
        <van-picker show-toolbar :columns="schoolNames" @cancel="showSchools = false" @confirm="pickSchool"
            :confirm-button-text="lang[userLang].confirm" :cancel-button-text="lang[userLang].cancel" />
    </van-popup>
</template>

<script>
import lang from "@/langs/PageLoginLang.js";
import {
    reactive,
    ref,
    computed,
    onMounted
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRouter,
    useRoute
} from "vue-router";
import {
    Button,
    Form,
    Field,
    Picker,
    Popup,
    Image as VanImage,
    Notify,
    CellGroup
} from "vant";
import UserTypes from "@/static_values/StaticUserTypes.js";
import myTheme from "@/logics/MyVantTheme.js";
import emailValidator from "@/logics/ValidatorEmail.js";
import passValidator from "@/logics/ValidatorPassword.js";
import Env from "@/logics/Envs.js";
import Login from "@/asyncs/Login.js";
import GetSchools from "@/asyncs/GetSchools.js";
import MessageChecker from "@/logics/MessageChecker.js";

export default {
    components: {
        [Button.name]: Button,
        [Form.name]: Form,
        [Field.name]: Field,
        [Picker.name]: Picker,
        [Popup.name]: Popup,
        [VanImage.name]: VanImage,
        [CellGroup.name]: CellGroup
    },
    setup() {
        const store = useStore();
        store.commit("showBack");
        store.commit("hideMenu");
        store.commit("setPageTitle", lang[store.state.lang].title);

        const router = useRouter();
        const route = useRoute();
        const formData = reactive({
            email: "",
            password: "",
            userType: {},
            school: {}
        });

        const userTypes = Object.values(UserTypes).map(a => {
            return {
                text: a.name[store.state.lang],
                value: a.value
            };
        });
        const showUserType = ref(false);
        const pickUserType = function (item) {
            formData.userType = item;
            showUserType.value = false;
        };

        const showSchools = ref(false);
        const schools = ref([]);
        const schoolNames = ref([]);
        GetSchools(schools, schoolNames);
        const pickSchool = function (text, index) {
            formData.school = schools.value[index];
            showSchools.value = false;
        }

        onMounted(function () {
            if (store.state.user) {
                store.commit("setUser", null);
                store.commit("setSchool", null);
                store.commit("setTimer", null);
            }
        });

        const form = ref(null);
        const submitting = ref(false);
        const doLogin = function () {
            if (submitting.value) {
                return;
            }
            form.value.validate().then(async function () {
                submitting.value = true;
                formData.email = formData.email.toLowerCase();
                const loginRes = await Login(formData.email, formData.password, formData.userType.value, formData.school.code);
                if (loginRes[0]) {
                    store.commit("setUser", loginRes[0]);
                    store.commit("setSchool", formData.school);
                    store.commit("setTimer", MessageChecker(store, route));
                    if (store.state.lastPage) {
                        const location = store.state.lastPage;
                        store.commit("setLastPage", null);
                        console.log(location);
                        window.location.href = location;
                    } else {
                        router.push("/home");
                    }
                } else {
                    submitting.value = false;
                    Notify({
                        type: "danger",
                        message: "登录信息错误，请检查您的用户名或密码或用户类型: " + loginRes[1],
                        duration: 6000
                    });
                }
            }).catch(function () {
                console.log("form validate false");
            });
        };

        const goReset = function () {
            router.push("/reset");
        };

        return {
            lang,
            userLang: computed(() => store.state.lang),
            Env,
            myTheme,
            formData,
            form,
            submitting,
            doLogin,
            userTypes,
            showUserType,
            pickUserType,
            schoolNames,
            schools,
            showSchools,
            pickSchool,
            passValidator,
            emailValidator,
            goReset
        };
    }
}
</script>
