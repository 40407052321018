export default {
    en: {
        title: "User Sign In",
        emailLabel: "Email",
        emailMessage: "Please enter your email address",
        emailFormatMessage: "There is something wrong with your email address",
        passLabel: "Password",
        passMessage: "Please enter your password",
        passFormatMessage: "Please do not use space in password, begin your password with letters",
        userTypeLabel: "User Type",
        userTypeMessage: "Please pick your user type",
        schoolLabel: "School",
        schoolMessage: "Please pick your school",
        login: "Sign In",
        placeholderEnter: "Please enter",
        placeholderPick: "Please pick",
        confirm: "Confirm",
        cancel: "Cancel",
        forget: "Forget Password"
    },
    zh: {
        title: "用户登录",
        emailLabel: "电子邮箱",
        emailMessage: "请填写电子邮箱",
        emailFormatMessage: "您输入的电子邮箱地址有错误呦",
        passLabel: "密码",
        passMessage: "请填写账户密码",
        passFormatMessage: "您输入的密码好像不太合适呦，请不要使用空格，并且字母在前",
        userTypeLabel: "用户类型",
        userTypeMessage: "请选择登录的用户类型",
        schoolLabel: "您的学校",
        schoolMessage: "请选择您的登录校区",
        login: "登录",
        placeholderEnter: "请输入",
        placeholderPick: "请选择",
        confirm: "确认",
        cancel: "取消",
        forget: "忘记密码"
    }
}