import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (email, password, userType, schoolCode) {
    try {
        const toSend = new FormData();
        toSend.append("youJian", email);
        toSend.append("miMa", password);
        toSend.append("userType", userType);
        toSend.append("schoolCode", schoolCode);
        const res = await axios.post(Env.freePath + "dengLu", toSend);
        return [res.data.user, res.data.error];
    } catch (err) {
        return [null, err];
    }
}